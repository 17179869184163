<template>
  <v-container class="max-container">
    <v-tabs
      v-model="active_tab"
      color="primary"
      background-color="grey darken-4"
      dark
      grow
    >
      <v-tab v-if="showTotalPrice" ref="monthTab" :key="'0'" class="text-none monthTab">
        Monthly Subscription
      </v-tab>
      <!--非审核版本并且有订阅记录的用户显示day pass-->
      <v-tab v-if="showTotalPrice" ref="daypassTab" :key="'1'" class="text-none daypassTab">
        Day Pass (Beta)
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="active_tab" dark background-color="transparent">
      <v-tab-item color="dark">
        <v-card flat color="black">
          <v-card-text>
            <div class="layout column align-center">
              <!-- <div v-show="!isWebView" class="v-card__title justify-center display-1 mb-2">
            <v-img :src="logo" max-height="120" max-width="120" />
          </div> -->
              <div class="v-card__subtitle justify-center display-2 mb-1" style="text-align:center;color:white;margin-top:1em">
                Choose Your Plan
              </div>
              <div style="color:#767676;margin-bottom:10px">
                Upgrade or cancel anytime
              </div>
            </div>
          </v-card-text>
          <choose-channel ref="chooseChannel" :change-handle="changeHandle" :show-price="showTotalPrice" />
          <!-- <div v-if="showTotalPrice" class="total-price-box">
        <div>Monthly Price  <span class="regular-price-text">${{ total_regular_price /100 }}</span></div>
        <div v-if="newUser" class="tip-text">
          After the 7 days free trial
        </div>
        <div class="total-price">${{ total_price/100 }}</div>
      </div> -->

          <div v-if="showTotalPrice && total_price>0" class="button-box">
            <v-btn block depressed class="tile text-none commit-subscription" color="cyan accent-4" :disabled="loading" :loading="one_month_loading" @click="selectPackage(1)">
              <span class="origin-price">${{ one_month_price.origin_price/100 }}</span> ${{ one_month_price.discount_price/100 }} for 1 months
            </v-btn>
            <v-btn block depressed class="tile text-none commit-subscription" color="cyan accent-4" :disabled="loading" :loading="six_month_loading" @click="selectPackage(6)">
              <span class="origin-price">${{ six_month_price.origin_price/100 }}</span> ${{ six_month_price.discount_price/100 }} for 6 months (save 20%)
            </v-btn>
            <v-btn block depressed class="tile text-none commit-subscription" color="cyan accent-4" :disabled="loading" :loading="twelve_month_loading" @click="selectPackage(12)">
              <span class="origin-price">${{ twelve_month_price.origin_price/100 }}</span> ${{ twelve_month_price.discount_price/100 }} for 12 months (save 30%)
            </v-btn>
          </div>
          <div v-else class="button-box">
            <v-btn block depressed class="tile text-none commit-subscription" color="cyan accent-4" :disabled="loading" :loading="loading" @click="subContinue()">Continue</v-btn>
          </div>
        </v-card>
      </v-tab-item>

      <v-tab-item color="dark">
        <v-card flat color="black">
          <v-card-text>
            <div class="layout column align-center">
              <!-- <div v-show="!isWebView" class="v-card__title justify-center display-1 mb-2">
            <v-img :src="logo" max-height="120" max-width="120" />
          </div> -->
              <div class="v-card__subtitle justify-center display-2 mb-1" style="text-align:center;color:white;margin-top:1em">
                Choose Your Channel
              </div>
              <div style="color:#767676;margin-bottom:10px">
                Pay as you go
              </div>
            </div>
          </v-card-text>
          <pay-go-channel ref="payGoChannel" :change-handle="changeHandle" :show-price="showTotalPrice" />
          <div class="button-box">
            <v-btn v-if="daily_total_amount>0" block depressed class="tile text-none commit-subscription" color="cyan accent-4" :disabled="loading" :loading="loading" @click="prePurchaseChannel">
              Pay ${{ daily_total_amount/100 }} for 2 days
            </v-btn>
            <v-btn v-else block depressed class="tile text-none commit-subscription" color="cyan accent-4" :disabled="loading" :loading="loading" @click="prePurchaseChannel">Pay</v-btn>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <!-- <base-back /> -->

    <c-snack-bar ref="csnackbar" />
    <over-lay-loading ref="overlayloading" />
    <over-dialog ref="overdialog" :ok-handle="subContinue" />
    <over-dialog ref="paymentFailedDialog" :ok-handle="goToPayment" />
    <over-dialog ref="payGoDialog" :ok-handle="purchaseChannel" />
    <over-dialog ref="payGoTopupDialog" :ok-handle="goToTopup" />
    <over-dialog ref="cancelSubDialog" :ok-handle="closeCancelDialog" :cancel-handle="cancelSub" />
  </v-container>
</template>

<script>

import {
  subscription,
  checkDefaultPayment,
  getLatestAppInfo,
  manualSub,
  getUserBalance } from '@/api/user'
import { getDeviceFrom, setDeviceFrom } from '@/util/auth'
import { isWebView } from '@/util/index'

import logo from '@/assets/logo1.png'

import CSnackBar from './components/snackbar'
import OverLayLoading from './components/overLayLoading'
import OverDialog from './components/overDialog'
import ChooseChannel from './components/chooseChannel'
import PayGoChannel from './components/payGoChannel'
import { useShepherd } from 'vue-shepherd'

export default {
  name: 'Subscription',
  components: {
    CSnackBar,
    OverLayLoading,
    OverDialog,
    ChooseChannel,
    PayGoChannel
  },
  data: () => ({
    valid: true,
    loading: false,
    active: null,
    logo: logo,
    channelList: [],
    total_price: 0,
    total_regular_price: 0,
    has_card: false,
    showTotalPrice: false,
    allowGoToPayment: false,
    isWebView: false,
    one_month_loading: false,
    six_month_loading: false,
    twelve_month_loading: false,
    sub_type: 1,
    active_tab: 0,
    daily_total_amount: 0,
    tour: null
  }),
  computed: {
    payGoUser() {
      return this.$store.state.user.userInfo.pay_as_go_user
    },
    newUser() {
      const sub = this.$store.state.user.userInfo.subscription
      return !sub
    },
    isTrial1() {
      const sub = this.$store.state.user.userInfo.subscription
      if (sub) {
        return sub.status === 'trial_1'
      } else {
        return false
      }
    },
    isTrial2() {
      const sub = this.$store.state.user.userInfo.subscription
      if (sub) {
        return sub.status === 'trial_2'
      } else {
        return false
      }
    },
    isTrial3() {
      const sub = this.$store.state.user.userInfo.subscription
      if (sub) {
        return sub.status === 'trial_3'
      } else {
        return false
      }
    },
    isActive() {
      const sub = this.$store.state.user.userInfo.subscription
      if (sub) {
        return sub.status === 'active'
      } else {
        return false
      }
    },
    isDisabled() {
      const sub = this.$store.state.user.userInfo.subscription
      return sub && sub.status === 'disable' // 用户订阅已被禁用（付款失败）
    },
    trial1_expire() {
      const sub = this.$store.state.user.userInfo.subscription
      return (sub && sub.status === 'trial_1' && sub.trial_end < Math.floor(Date.now() / 1000))
    },
    sub_canceled() {
      const sub = this.$store.state.user.userInfo.subscription
      return sub && sub.status === 'cancel' // 用户订阅已被取消状态
    },
    one_month_price() {
      const discount_price = this.calcPrice('discount_price') * 1
      const origin_price = this.calcPrice('origin_price') * 1

      return { 'origin_price': origin_price, 'discount_price': discount_price }
    },
    six_month_price() {
      const discount_price = (this.calcPrice('discount_price') * 6 * 0.8).toFixed()
      const origin_price = (this.calcPrice('origin_price') * 6).toFixed()

      return { 'origin_price': origin_price, 'discount_price': discount_price }
    },
    twelve_month_price() {
      const discount_price = (this.calcPrice('discount_price') * 12 * 0.7).toFixed()
      const origin_price = (this.calcPrice('origin_price') * 12).toFixed()

      return { 'origin_price': origin_price, 'discount_price': discount_price }
    }
  },
  created() {
    const from = this.$route.query.from
    if (from) {
      window.Intercom('update', {
        'hide_default_launcher': true
      })
      setDeviceFrom(from)
    }
    if (getDeviceFrom() && ['ios', 'android'].includes(getDeviceFrom().toLowerCase())) {
      window.Intercom('update', {
        'hide_default_launcher': true
      })
    }

    this.$nextTick(() => {
      this.isWebView = isWebView()
    })
    this.setShowPayment()

    try {
      const tab = this.$route.query.tab
      if (tab) {
        this.active_tab = parseInt(tab)
      }
    } catch (error) {
      console.log(error)
    }

    // 已经有订阅记录并且不是day pass 用户显示tour
    // if (!this.newUser && !this.payGoUser) { this.createTour() }
  },
  mounted() {
    // setTimeout(() => {
    //   if (this.newUser) { this.tour.start() }
    // }, 2 * 1000)
  },
  methods: {
    createTour() {
      this.tour = useShepherd({
        useModalOverlay: true
      })

      this.tour.addSteps([
        {
          id: 'Step 1',
          attachTo: { element: '', on: 'bottom' },
          text: `<div class="tour-box">
                  <h2>Welcome</h2> 
                  <div class="tour-content">
                  We offer two types of service--monthly subscription and day pass--to cater for different usage patterns.
                  </div>
                </div>`,
          buttons: [
            {
              text: 'Next',
              action: this.tour.next
            }
          ]
        },
        {
          id: 'Step 2',
          attachTo: { element: '.monthTab', on: 'bottom' },
          text: `<div class="tour-box">
                  <h2 class="step-title">Monthly Subscription</h2> 
                  <div class="tour-content">
                  Unlimited access and auto renewed on monthly basis. It's the most economical if you watch the channels frequently.
                  <br><br>You can keep a dedicated profile in monthly subscribed account.
                  </div>
                </div>`,
          buttons: [
            {
              text: 'Back',
              action: this.tour.back
            },
            {
              text: 'Next',
              action: this.tour.next
            }
          ]
        },
        {
          id: 'Step 3',
          attachTo: { element: '.daypassTab', on: 'bottom' },
          text: `<div class="tour-box">
                  <h2 class="step-title">Day Pass</h2> 
                  <div class="tour-content">
                  One-time charge for a limited duration. This is perfect if you need intermittent access to channels but don't want to commit.
                  <br><br>All profiles are shared in Day Pass account.
                  </div>
                </div>`,
          buttons: [
            {
              text: 'Back',
              action: this.tour.back
            },
            {
              text: 'Next',
              action: this.tour.next
            }
          ]
        },
        {
          id: 'Step 4',
          attachTo: { element: '.image-item', on: 'bottom' },
          text: `<div class="tour-box">
                  <h2 class="step-title">Select Channel</h2> 
                  <div class="tour-content">
                  You can select any channel combination and change it anytime.
                  </div>
                </div>`,
          buttons: [
            {
              text: 'Back',
              action: this.tour.back
            },
            {
              text: 'Next',
              action: this.tour.next
            }
          ]
        },
        {
          id: 'Step 5',
          attachTo: { element: '.image-item', on: 'bottom' },
          text: `<div class="tour-box">
                  <div class="tour-content">
                  If you downgrade to a cheaper monthly subscribed package, 
                  the unused amount will be kept as credit and deducted from your next bill.
                  </div>
                </div>`,
          buttons: [
            {
              text: 'Back',
              action: this.tour.back
            },
            {
              text: 'Next',
              action: this.tour.next
            }
          ]
        },
        {
          id: 'Step 6',
          attachTo: { element: '', on: 'bottom' },
          text: `<div class="tour-box">
                  <div class="tour-content">
                  Congratulations! You can make modification any time in the app.
                  </div>
                </div>`,
          buttons: [
            {
              text: 'Back',
              action: this.tour.back
            },
            {
              text: 'Done',
              action: this.tour.complete
            }
          ]
        }
      ]
      )
      // this.tour.start()
    },
    setShowPayment() {
      // 当前登录用户是审核版本专用的账号
      if (this.$store.state.user.userInfo.is_native) {
        this.showTotalPrice = false
        this.allowGoToPayment = false
        return
      }
      this.$nextTick(() => {
        if (window.isWebKit() || window.isAndroidWebView()) { // webview env
        // 1. get server app renew info
        // 2. get appinfo from call native  func
        // 3. compare app_version and package
          getLatestAppInfo().then(res => {
            // res.data.value ->  {"WKChannel.Mobile.App":{"app_version": "2.0.1","platform": "ios","app_name":"One Channel Mobile"}}
            this.$store.dispatch('user/getNativeAppInfo').then(data => {
              // data -> {"app_name": "One Channel Mobile", "package": "WKChannel.Mobile.App", "platform": "ios", "app_version": "2.0.1"}
              if (!data) {
                return
              }

              const appinfo = data

              if (Object.keys(data).includes('userAgent')) {
                const package_name = Object.keys(res.data.value).find(item => {
                  return data.userAgent.includes(item)
                })
                if (!package_name) {
                  return
                }
                // 支持Android 多版本审核
                const renew_versions = res.data.value[package_name]['app_version'].split(',')
                if (renew_versions.some(x => data.userAgent.includes(x.trim()))) {
                  // 当前用户的app version在审核version list中， 直接返回
                  return
                } else {
                  this.showTotalPrice = true
                  this.allowGoToPayment = true
                }
              }
              if (res.data.value[appinfo.package]['app_version'] !== appinfo['app_version']) {
                this.showTotalPrice = true
                this.allowGoToPayment = true
              }
            })
          }, error => {
            console.log(error)
          })
        } else {
          this.showTotalPrice = true
          this.allowGoToPayment = true
        }
      })
    },
    changeHandle(from) {
      if (from === 'paygo') {
        this.daily_total_amount = this.$refs.payGoChannel.calcPrice()
        return
      }
      this.total_price = this.$refs.chooseChannel.calcPrice()
      this.total_regular_price = this.$refs.chooseChannel.calcRegular()
      this.channelList = this.$refs.chooseChannel.channelList
    },
    cancelSub() {
      this.$router.push({ name: 'CancelSub', query: { 'survey_id': '27324846' }})
    },
    closeCancelDialog() {
      this.$refs.cancelSubDialog.controlLoading(false)
      this.$refs.cancelSubDialog.controlShowDialog(false)
    },
    getCancelTipMsg() {
      const current_sub = this.$store.state.user.userInfo.subscription
      const discount_price_per = this.channelList
        .map((item) => {
          return current_sub.platforms.includes(item.platform_name) ? item.discount_price : 0
        })
        .reduce((acc, val) => acc + val)

      const origig_price_per = this.channelList
        .map((item) => {
          return current_sub.platforms.includes(item.platform_name) ? item.origin_price : 0
        })
        .reduce((acc, val) => acc + val)

      const saved_amount = (origig_price_per - discount_price_per) / 100
      const saved_amount_total = Math.ceil(
        (parseInt(Date.now() / 1000) - current_sub.start_date) / (30 * 24 * 60 * 60)) * saved_amount
      const cancel_msg = `  You are currently saving $${saved_amount} every month and have saved a total of $${saved_amount_total} so far. Would you like to continue saving?`
      return cancel_msg
    },
    async subContinue(is_active) {
      if (this.total_price === 0) {
        if (this.isTrial2 || this.isTrial3 || this.isActive || this.isDisabled) {
          this.$refs.cancelSubDialog.controlDialog(true, this.getCancelTipMsg(),
            'Cancel Subscription', false, true, 'Yes', 'Cancel')
        } else {
          this.showNackBar('info', 'Please select at least one platform')
        }
        return
      }
      this.setLoading(true)
      const default_card = await checkDefaultPayment()
      this.has_card = default_card.code === 0
      if (!this.has_card && this.allowGoToPayment) {
        const platforms = this.channelList.filter(item => item.check)
        this.$store.dispatch('user/setSubData', platforms)
        this.$router.push({ name: 'payment', query: { 'st': this.sub_type }})
        return
      }

      const operations = await this.getOperations(is_active)
      var platform_list = this.channelList.filter(item => item.check).map(item => item.platform_name)
      subscription({ 'operations': operations, 'platform_list': platform_list, 'interval_count': this.sub_type }).then(async(res) => {
        // 操作成功
        console.log(res)
        await this.$store.dispatch('user/getInfo')

        // 订阅支付失败， 提示用户更新支付方式
        if (res.code === 4503) {
          this.$refs.paymentFailedDialog.controlDialog(true, res.message, 'Payment failed', false, false)
          return
        }

        if (res.code === 0) {
          if (is_active) {
            this.$refs.overdialog.controlLoading(false)
            this.$refs.overdialog.controlShowDialog(false)
          }
          if (this.allowGoToPayment) {
            this.showNackBar('info', res.message)
          }
          this.goTo()
          return
        }
        // 提示有绑定支付方式， 但无试用机会, 是否进入正式订阅 (active) 状态
        // eslint-disable-next-line
        if (res.code !== 0 && res.hasOwnProperty('data') && res.data && res.data.hasOwnProperty('card')&& res.data.hasOwnProperty('trial') && res.data['card'] && !res.data['trial']) {
          this.showNackBar('info', res.message)
          this.$refs.overdialog.controlDialog(true, res.data.active_tip)
          return
        }

        if (res.code !== 0) {
          this.showNackBar('error', res.message)
          return
        }

        this.showNackBar('error', res.message)
      }, () => {
        this.showNackBar('error', 'System error, please try again later!')
      }).finally(() => {
        this.setLoading(false)
        if (is_active) {
          this.$refs.overdialog.controlLoading(false)
          this.$refs.overdialog.controlShowDialog(false)
        }
      })
    },
    goToPayment() {
      this.$refs.paymentFailedDialog.controlLoading(false)
      this.$refs.paymentFailedDialog.controlShowDialog(false)
      this.$router.push({ name: 'payment', query: { 'st': this.sub_type }})
    },
    showNackBar(color, message, timeout = 5000) {
      this.$refs.csnackbar.show(message, color, timeout)
    },
    goTo() {
      if (window.isAndroidWebView()) {
        window.AndroidCallback.onSubscriptionCallback('success')
        return
      }

      if (window.isWebKit()) {
        window.webkit.messageHandlers.refreshUserSubInfo.postMessage('success')
        return
      }

      this.$router.push({ name: 'mychannel' })
    },
    calcPrice(price_str) {
      if (this.channelList.length === 0) {
        return 0
      }
      return this.channelList
        .map((item) => {
          return item.check ? item[price_str] : 0
        })
        .reduce((acc, val) => acc + val)
    },
    selectPackage(package_type) {
      this.sub_type = package_type
      this.subContinue()
    },
    setLoading(op) {
      this.loading = op
      if (this.sub_type === 1) {
        this.one_month_loading = op
        return
      }

      if (this.sub_type === 6) {
        this.six_month_loading = op
        return
      }

      if (this.sub_type === 12) {
        this.twelve_month_loading = op
        return
      }
    },
    async getOperations(is_active) {
      // is_active: 是否进入正式订阅（active）状态
      if (is_active) {
        return 'active'
      }

      // const default_card = await checkDefaultPayment()
      // const has_card = default_card.code === 0
      // 新用户, 没有绑定支付方式， 试用1天
      if (this.newUser && !this.has_card) {
        return 'trial_1'
      }

      if (this.newUser && this.has_card && this.payGoUser) {
        return 'active'
      }

      // 新用户, 有绑定支付方式， 试用7天
      if (this.newUser && this.has_card) {
        return 'trial_2'
      }

      // 不是新用户（已经有订阅），有绑定支付方式， 当前已订阅的状态为trial_1, 继续试用7天
      if (!this.newUser && this.has_card && this.isTrial1) {
        return 'trial_3'
      }

      // 不是新用户（已经有订阅），没有绑定支付方式， 当前状态为trial_1并已过期, 不允许执行此操作
      if (!this.newUser && !this.has_card && this.isTrial1 && this.trial1_expire) {
        return 'not_allowed'
      }

      // 当前用户有支付方式, 并且订阅已被取消时, 则恢复用户订阅
      if (this.has_card && this.sub_canceled) {
        return 'recover'
      }

      return 'update'
    },
    async purchaseChannel() {
      if (this.daily_total_amount <= 0) {
        this.showNackBar('info', 'Please select at least one platform')
        return
      }
      this.loading = true
      const res_data = await getUserBalance()
      if (res_data.balance < this.daily_total_amount) {
        this.$refs.payGoDialog.controlDialog(true, 'Your account balance is insufficient, please top up', 'Insufficient balance', false, true, 'Top Up', 'Cancel')
        this.loading = false
        return
      }
      const platform_list = this.$refs.payGoChannel.channelList.filter(item => item.check).map(item => item.platform_name)
      manualSub({ 'platform_list': platform_list }).then(async res => {
        if (res.code !== 0) {
          this.showNackBar('info', res.message)
          return
        }
        this.showNackBar('success', res.message)
        await this.$store.dispatch('user/getInfo')
        this.goTo()
      }, error => {
        console.log(error)
      }).finally(() => {
        this.loading = false
        this.$refs.payGoDialog.controlLoading(false)
        this.$refs.payGoDialog.controlShowDialog(false)
      })
    },
    async prePurchaseChannel() {
      if (this.daily_total_amount <= 0) {
        this.showNackBar('info', 'Please select at least one channel')
        return
      }
      this.loading = true
      const res_data = await getUserBalance()
      if (res_data.data.balance < this.daily_total_amount) {
        this.$refs.payGoTopupDialog.controlDialog(true, 'Your account balance is insufficient, please top up.', 'Insufficient balance', false, true, 'Top Up', 'Cancel')
        this.loading = false
        return
      }

      this.$refs.payGoDialog.controlDialog(true, `This will deduct $${this.daily_total_amount / 100} from your Day Pass account, do you want to continue?`, 'Confirm', false, true, 'Continue', 'Cancel')
      this.loading = false
      return
    },
    goToTopup() {
      this.$router.push({ name: 'MyWallet', query: { 'redirect': this.$route.path, 'tab': 1 }})
    }
  }
}
</script>
<style scoped lang="scss">
.platform-text{
    font-size: 20px;
    font-weight: 320;
}

.text--primary{
    font-size: 18px;
}

.total-price-box{
    color: white;
    text-align: center;
    font-size:medium;
    font-weight: bold;
    padding: 16px;
    .total-price{
        font-size:1.8em;
        font-weight: bold;
        // padding-top: 3px;
        color: #8C9EFF;
    }
}

.container .container--fluid .fill-height{
  background: white!important;
}

.image-item{
  border-radius: 9px;
  position: relative;
  height:90px;
  width:150px;
  .v-image{
    height:78px;
    border-radius: 5px;
  }
  .check-icon-box{
    position: relative;
    top: -82px;
    left: -1px;
    width: 20px;
    background: #E040FB;
    border-radius: 0 0 8px 0;
  }
}

.box-border-radius{
  border: 6px solid #E040FB;
}

.button-box{
  margin-top:20px;
  .v-btn{
    margin-top: 12px;
    background-image: linear-gradient(45deg,#8C9EFF, #E040FB)!important;
    font-weight: bold;
    letter-spacing: 0.05em;
    font-size: 14px;
    min-width: 0;
    .v-btn__content{
      padding: 0;
    }
  }
  .origin-price{
    text-decoration-line: line-through;
    padding-right: 10px;
  }
}

.channels-row{
  text-align:center;
  text-align: -webkit-center;
  .image-item{
    text-align: left!important;
  }
}

.v-card__subtitle, .v-card__text, .v-card__title {
    padding: 10px;
}

.tip-text{
  color: white;
  font-size: smaller;
}

.v-sheet button.v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab){
  padding: 0!important;
}

.theme--dark.v-tabs-items {
    background-color: unset !important;
}

</style>
