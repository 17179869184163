<template>
  <div class="subplan-box">
    <v-row
      align="center"
      align-content="center"
      justify="center"
      class="channels-row"
    >
      <div v-if="!overloadingStatus&&channelList.length===0">You have subscribed to all channels, there are currently no channels available for purchase.</div>
      <v-col
        v-for="item in channelList"
        :key="item.platform_name"
        xs="6"
        sm="4"
        md="4"
        lg="4"
        xl="4"
        align-self="center"
      >
        <div
          class="image-item"
          :class="{ 'box-border-radius': item.check }"
          @click="change(item)"
        >
          <v-img :src="item.channel_photo" />
          <div v-show="item.check" class="check-icon-box">
            <v-icon left color="white"> mdi-check </v-icon>
          </div>
        </div>
        <div v-show="showPrice" class="item-price-box">
          <span class="item-discount">${{ item.amount/100 }} <label for="">for {{ item.interval_count }} days</label> </span>
        </div>
      </v-col>
    </v-row>
    <over-lay-loading ref="overlayloading" />
  </div>
</template>

<script>
import { getChannel, getChannelDailyPrice } from '@/api/user'
import OverLayLoading from './overLayLoading'

export default {
  name: 'PayGoChannel',
  components: {
    OverLayLoading
  },
  props: {
    changeHandle: {
      type: Function,
      default: () => {}
    },
    showPrice: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    valid: true,
    loading: false,
    active: null,
    channelList: [],
    from: 'paygo',
    overloadingStatus: true
  }),
  computed: {
    userSubscribeChannels() {
      const sub = this.$store.state.user.sub_data
      if (sub) {
        return sub.map((item) => item.platform_name)
      }
      return []
    },
    subActiveChannels() {
      const sub = this.$store.state.user.userInfo.subscription
      if (sub && sub.status === 'trial_1' && sub.trial_end < Math.floor(Date.now() / 1000)) {
        return []
      }
      if (sub && sub.status !== 'cancel') {
        return sub.platforms.split(';')
      }
      return []
    }
  },
  created() {
    this.$nextTick(() => {
      this.get_channel()
    })
  },
  methods: {
    get_channel() {
      this.$refs.overlayloading.controlShow(true)
      Promise.all([getChannel(), getChannelDailyPrice()]).then((values) => {
        const channel_list = values[0].data
        const daily_channel_list = values[1].data
        this.channelList = channel_list
          .filter((item) => {
            // 移除尚未定价的channel
            if (!daily_channel_list.find(_item => _item.platform === item.platform_name)) {
              return false
            }
            if (item.status === 2) {
              // 暂停使用的channel
              return this.userSubscribeChannels.concat(this.payGoPlatforms).includes(
                item.platform_name
              )
            }
            // 移除正在周期性订阅的channel， 不可以同时存在
            if (this.subActiveChannels.find(platform_name => platform_name === item.platform_name)) {
              return false
            }
            return item.status === 0
          })
          .map((item) => {
            const daily_channel_item = daily_channel_list.find(_item => _item.platform === item.platform_name)
            item.interval_count = daily_channel_item.interval_count
            item.interval = daily_channel_item.interval
            item.amount = daily_channel_item.amount
            item.check = false
            return item
          })
        this.changeHandle(this.from)
      }).finally(() => {
        this.$refs.overlayloading.controlShow(false)
        this.overloadingStatus = false
      })
    },
    change(item) {
      item.check = !item.check
      this.changeHandle(this.from)
    },
    calcPrice() {
      return this.channelList
        .map((item) => {
          return item.check ? item.amount : 0
        })
        .reduce((acc, val) => acc + val)
    },
    calcRegular() {
      return this.channelList
        .map((item) => {
          return item.check ? item.origin_price : 0
        })
        .reduce((acc, val) => acc + val)
    }
  }
}
</script>
<style scoped lang="scss">
.platform-text {
  font-size: 20px;
  font-weight: 320;
}

.text--primary {
  font-size: 18px;
}

.total-price-box {
  color: white;
  text-align: center;
  font-size: medium;
  font-weight: bold;
  padding: 16px;
  .total-price {
    font-size: 1.8em;
    font-weight: bold;
    // padding-top: 3px;
    color: #8c9eff;
  }
}

.container .container--fluid .fill-height {
  background: white !important;
}

.image-item {
  border-radius: 9px;
  position: relative;
  height: 68px;
  width: 120px;
  .v-image {
    height: 60px;
    border-radius: 4px;
  }
  .check-icon-box {
    position: relative;
    top: -60px;
    left: -2px;
    width: 20px;
    background: #e040fb;
    border-radius: 0 0 8px 0;
  }
}

.box-border-radius {
  border: 4px solid #e040fb;
}

.button-box {
  margin-top: 10px;
  .v-btn {
    background-image: linear-gradient(45deg, #8c9eff, #e040fb) !important;
    font-weight: bold;
    letter-spacing: 0.05em;
  }
}

.channels-row {
  text-align: center;
  text-align: -webkit-center;
  .image-item {
    text-align: left !important;
  }
  .item-price-box{
    text-align: center;
    .item-discount{
      color: #8C9EFF;
      padding-left: 5px;
      font-size: 20px;
    }
    .item-origin{
      font-size: 12px;
      color: white;
      text-decoration-line: line-through;
    }
    label{
      font-size: 14px!important;
    }
  }
}

.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 10px;
}

.tip-text {
  color: white;
  font-size: smaller;
}

.subplan-box {
  min-height: 120px;
}

</style>
